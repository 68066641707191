import Layout from "layouts/DashboardLayout.jsx";
import {
    Login,
    Register,
    Error400,
    ForgotPassword,
    Error500,
    EmailVerification,
    ResetPassword,
    GemeoDigital
} from "./../views/pages/index";

const indexRoutes = [
    { path: "/login", component: Login },
    { path: "/register", component: Register },
    { path: "/verify_email/:token", component: EmailVerification },
    { path: "/error400", component: Error400 },
    { path: "/error500", component: Error500 },
    { path: "/forgotPassword", component: ForgotPassword },
    { path: "/reset-password/:token", component: ResetPassword },
    { path: "/", component: Layout },
    { path: "/gemeodigital", component: GemeoDigital },
];


export default indexRoutes;
