import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
    return (
        <div>
            <Fragment>{children}</Fragment>
        </div>
    );
};
