import React, { Fragment } from "react";
import SidebarWrapper from "./sidebar.style";
import Radium from "radium";
import NavList from "components/sidebar/NavList";
import { sidebarData } from "util/data/sidebar";
import { iconDemo, AppName } from "helper/constant";
import { Scrollbars } from "react-custom-scrollbars";
import { NavLink } from "react-router-dom";
import IntlMessages from "util/intlMessages";

const Sidebar = props => {
  let listNameStyle;
  let sidebar;
  let sideScrollStyle;

  const {
    mini,
    drawerWidth,
    miniDrawerWidth,
    onMouseEnter,
    onMouseLeave,
    sidebarTheme,
    layoutTheme,
    closeDrawer,
    themeSetting
  } = props;

  if (
    themeSetting.toolbarAlignValue === "above" &&
    themeSetting.footerAlignValue === "above"
  ) {
    sideScrollStyle = {
      zIndex: 5,
      height: "calc(100vh - 190px)"
    };
  } else if (themeSetting.toolbarAlignValue === "above") {
    sideScrollStyle = {
      zIndex: 5,
      height: "calc(100vh - 145px)"
    };
  } else if (themeSetting.footerAlignValue === "above") {
    sideScrollStyle = {
      zIndex: 5,
      height: "calc(100vh - 128px)"
    };
  } else {
    sideScrollStyle = {
      zIndex: 5,
      height: "calc(100vh - 75px)"
    };
  }

  if (themeSetting.sidebarTransParentValue === "on") {
    sidebar = {
      backgroundImage: `#035184`,
      backgroundRepeat: "no-repeat, repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      width: mini ? miniDrawerWidth : drawerWidth,
      "@media (max-width: 991.98px)": {
        width: mini ? 0 : drawerWidth
      }
    };
  } else {
    sidebar = {
      width: mini ? miniDrawerWidth : drawerWidth,
      background: sidebarTheme.backgroundColor,
      "@media (max-width: 991.98px)": {
        width: mini ? 0 : drawerWidth
      }
    };
  }

  const closeIcon = {
    "@media (max-width: 991.98px)": {
      display: "block"
    }
  };

  if (mini) {
    listNameStyle = {
      opacity: miniDrawerWidth === drawerWidth ? 1 : 0,
      transform:
        miniDrawerWidth === drawerWidth
          ? "translateZ(0)"
          : "translate3d(-25px,0,0)"
    };
  } else {
    listNameStyle = {
      opacity: !mini ? 1 : 0,
      transform: !mini ? "translateZ(0)" : "translate3d(-25px,0,0)"
    };
  }


};
export default Radium(Sidebar);
