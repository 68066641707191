import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import "./potree.css";

function PotreePointcloud({ email, project, demo }) {

    useEffect(() => {
        const viewer = new window.Potree.Viewer(document.getElementById("potree_render_area"));

        viewer.setEDLEnabled(false);
        viewer.setFOV(60);
        viewer.setPointBudget(1_000_000);
        viewer.loadSettingsFromURL();
        viewer.setBackground("skybox");
        viewer.loadGUI(() => {
            viewer.setLanguage('br');
            window.$("#menu_tools").next().show();
            window.$("#menu_clipping").next().show();
            viewer.toggleSidebar();
        });

        let metadataUrl;

        metadataUrl = `https://storage.googleapis.com/ds-web-repository/enecad/${encodeURIComponent(project.name)}/metadata.json`;
 

        fetch(metadataUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(metadata => {
                const name = metadata.name;
                viewer.setDescription(name);

                window.Potree.loadPointCloud(metadataUrl, name, (e) => {
                    let scene = viewer.scene;
                    let pointcloud = e.pointcloud;
                    let material = pointcloud.material;
                    material.size = 1;
                    material.pointSizeType = window.Potree.PointSizeType.ADAPTIVE;
                    material.shape = window.Potree.PointShape.SQUARE;

                    scene.addPointCloud(pointcloud);
                    viewer.fitToScreen();
                });
                
            })
            .catch(error => {
                console.error("Failed to load pointcloud metadata:", error);
            });
    }, [email, project, demo]);

    return (
        <div style={{ position: 'absolute', top: '0px', bottom: '0', left: '0', right: '0' }}>
            <div id="potree_render_area" style={{ position: 'absolute', width: '100%', height: '100%', overflow: 'hidden' }}></div>
            {/* SIDEBAR */}
            <div id="potree_sidebar_container"></div>
        </div>
    );
}

const mapStateToProps = state => ({
    email: state.auth.email
});

export default connect(mapStateToProps)(PotreePointcloud);